import React, { useRef, useEffect, useState } from "react";
import "pannellum";
import "pannellum/src/css/pannellum.css";
import "./custom.css";
// import test360 from "../../../../images/testPano.jpg";
import DraggableComp from "../Draggable/Draggable";
import styles from "./Image360.module.css";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import AddCircle from "@material-ui/icons/AddCircle";
import Save from "@material-ui/icons/Save";
import Fullscreen from "@material-ui/icons/Fullscreen";
import FullscreenExit from "@material-ui/icons/FullscreenExit";
import {
  Paper,
  TextField,
  Button,
  Chip,
  Select,
  MenuItem,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ReactDOM from "react-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import secureLocalStorage from "react-secure-storage";

const Image360 = (props) => {
  const viewerRef = useRef(null);
  const [viewerState, setViewerState] = useState(null);
  const isDraggingRef = useRef(false);
  const [editButton, setEditButton] = useState(false);
  const [addButton, setAddButton] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [startAnnotation, setStartAnnotation] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const [showPaper, setShowPaper] = useState(false);
  const [status, setStatus] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });
  const buttonContainerRef = useRef(null);
  const mainContainerRef = useRef(null);
  const [selectedHotSpot, setSelectedHotSpot] = useState(null);
  const [tags, setTags] = useState([]);
  const [hotSpotArray, setHotSpotArray] = useState([]);
  const redDivRef = useRef(null);
  const [editPermissions, setEditPermissions] = useState(false);

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handle = useFullScreenHandle();

  function getHotspots() {
    // Access the hotspots from the viewer config
    var config = viewerState.getConfig();
    if (config && config.hotSpots) {
      return config.hotSpots;
    } else {
      return [];
    }
  }

  const removeHotSpotHandler = () => {
    viewerState.removeHotSpot(selectedHotSpot);
  };

  const saveHotSpotsHandler = () => {
    const hotspots = getHotspots();
    const hotspotArray = hotspots.map((hotspot) => hotspot.clickHandlerArgs);
    setHotSpotArray(hotspotArray);
    console.log("hotspotArray", hotspotArray);
    const updatedHotspotArray = hotspotArray.map((hotspotParam) => {
      return {
        ...hotspotParam,
        folderId: props.selectedImage.getData.selectedFoldId,
        projectId: props.selectedImage.getData.selectedProjId,
        imgId: props.selectedImage.getData.selectedImageId,
      };
    });
    props.saved(
      updatedHotspotArray,
      props.selectedImage.getData.selectedImageId
    );
  };
  const handleFormSubmit = () => {
    handleMouseDown();
    let hotspots = getHotspots();
    var hotspotToUpdate = hotspots.find(function(hotspot) {
      return hotspot.clickHandlerArgs.id === selectedHotSpot;
    });

    // Get the hotspots and log them to the console

    if (hotspotToUpdate) {
      console.log(
        "FormData",

        formData,
        viewerState,
        selectedHotSpot,
        hotspotToUpdate,
        hotspots
      );

      removeHotSpotHandler();

      const optional = {
        randomId: selectedHotSpot,
        title: formData.title,
        description: formData.description,
        tags: tags,
      };

      viewerFunctions(
        viewerState,
        hotspotToUpdate.pitch,
        hotspotToUpdate.yaw,
        optional
      );

      // Update the hotspot in the viewer
      //viewerState.updateHotSpot(selectedHotSpot, hotspotToUpdate);
    }

    removePaperCard();
    // You can add further logic to handle form submission here
  };

  const appendAddButtonHanlder = () => {
    // Add the custom button to the controls container
    const controlsContainers = document.querySelectorAll(
      ".pnlm-controls-container"
    );

    // Select the most recently added .pnlm-controls-container
    const controlsContainer = controlsContainers[controlsContainers.length - 1];

    if (controlsContainer && !buttonContainerRef.current) {
      const buttonContainer = document.createElement("div");
      buttonContainerRef.current = buttonContainer;
      controlsContainer.appendChild(buttonContainer);
    }

    if (buttonContainerRef.current) {
      ReactDOM.render(
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // optional, to center buttons horizontally
          }}
        >
          <Tooltip
            title={"Add Hotspots"}
            style={{ zIndex: 999999999999999999 }}
            placement="left"
          >
            <IconButton
              onClick={(e) => {
                setAddButton(true);
                setStartAnnotation(!startAnnotation);
              }}
              onMouseEnter={() => setAddButton(false)}
              onMouseLeave={() => setAddButton(true)}
              aria-label="add-hotspot"
              style={{
                height: "2px",
                width: "2px",
                color: "black",
                margin: "10% 2% 2% 2%",
                borderRadius: "5px",
                backgroundColor: startAnnotation ? "grey" : "white",
                borderRadius: 0, // to make it square
              }}
            >
              <AddCircle />
            </IconButton>
          </Tooltip>
          {/* <Tooltip
            title={"Previous Image"}
            style={{ zIndex: 999999999999999999 }}
            placement="left"
          >
            <IconButton
              onClick={(e) => {
                setAddButton(true);
                setStartAnnotation(!startAnnotation);
              }}
              onMouseEnter={() => setAddButton(false)}
              onMouseLeave={() => setAddButton(true)}
              aria-label="add-hotspot"
              style={{
                height: "2px",
                width: "2px",
                color: "black",
                margin: "10% 2% 2% 2%",
                borderRadius: "5px",
                backgroundColor: startAnnotation ? "grey" : "white",
                borderRadius: 0, // to make it square
              }}
            >
              <SkipPreviousIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={"Zoom to Layer"}
            style={{ zIndex: 999999999999999999 }}
            placement="left"
          >
            <IconButton
              onClick={(e) => {
                setAddButton(true);
                setStartAnnotation(!startAnnotation);
              }}
              onMouseEnter={() => setAddButton(false)}
              onMouseLeave={() => setAddButton(true)}
              aria-label="add-hotspot"
              style={{
                height: "2px",
                width: "2px",
                color: "black",
                margin: "10% 2% 2% 2%",
                borderRadius: "5px",
                backgroundColor: startAnnotation ? "grey" : "white",
                borderRadius: 0, // to make it square
              }}
            >
              <ZoomInIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={"Next Image"}
            style={{ zIndex: 999999999999999999 }}
            placement="left"
          >
            <IconButton
              onClick={(e) => {
                setAddButton(true);
                setStartAnnotation(!startAnnotation);
              }}
              onMouseEnter={() => setAddButton(false)}
              onMouseLeave={() => setAddButton(true)}
              aria-label="add-hotspot"
              style={{
                height: "2px",
                width: "2px",
                color: "black",
                margin: "10% 2% 2% 2%",
                borderRadius: "5px",
                backgroundColor: startAnnotation ? "grey" : "white",
                borderRadius: 0, // to make it square
              }}
            >
              <SkipNextIcon />
            </IconButton>
          </Tooltip> */}
          {/* <Tooltip
            title={"Save"}
            style={{ zIndex: 999999999999999999 }}
            placement="left"
          >
            <IconButton
              aria-label="save"
              onClick={(e) => {
                saveHotSpotsHandler();
                setStartAnnotation(false);
              }}
              onMouseEnter={() => setAddButton(false)}
              onMouseLeave={() => setAddButton(true)}
              style={{
                height: "2px",
                width: "2px",
                color: "black",
                margin: "10% 2% 2% 2%",
                borderRadius: "5px",
                backgroundColor: "white",
                borderRadius: 0, // to make it square
              }}
            >
              <Save />
            </IconButton>
          </Tooltip> */}
          <Tooltip
            title={"Full Screen"}
            style={{ zIndex: 999999999999999999 }}
            placement="left"
          >
            <IconButton
              onClick={(e) => {
                setFullScreen(!fullScreen);
                setStartAnnotation(false);
              }}
              aria-label="full-screen"
              onMouseEnter={() => setAddButton(false)}
              onMouseLeave={() => setAddButton(true)}
              style={{
                height: "2px",
                width: "2px",
                color: "black",
                margin: "10% 2% 2% 2%",
                borderRadius: "5px",
                backgroundColor: "white",
                borderRadius: 0, // to make it square
              }}
            >
              {fullScreen ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>
          </Tooltip>
        </div>,
        buttonContainerRef.current
      );
    }
  };

  // const selectedImage = props.selectedImage;

  useEffect(() => {
    let imgType = "raw";

    let selectedImage = props.selectedImage.src;
    let selectedImageAttr = props.selectedImage;
    setStatus(
      props.selectedImage.k.reviewStatus
        ? props.selectedImage.k.reviewStatus
        : "view"
    );
    //code to check the permissions
    const item = props.appStateProjects.find(
      (obj) => obj.id === selectedImageAttr?.getData?.selectedProjId
    );
    if (
      item?.permission?.edit &&
      secureLocalStorage.getItem("user") !== "share"
    ) {
      setEditPermissions(true);
    }
    console.log(
      "selectedImageAttr",
      selectedImageAttr,
      item,
      props.appStateProjects,
      selectedImageAttr?.getData?.selectedProjId
    );
    if (props.selectedImage?.imgType === "multiRes") {
      const accessToken = props.authHeader();

      let changedUrl = selectedImage.replace(/%3CTOKEN%3E/g, accessToken);
      changedUrl = changedUrl.split("?")[0];

      selectedImage = changedUrl;
      imgType = "multiRes";
      // console.log(
      //   "selectedImage123",
      //   selectedImage,
      //   imgType,

      //   "changedUrl",
      //   changedUrl,

      //   "props.selectedImage",
      //   props.selectedImage
      // );
    }

    let ConfigObj = null;

    let viewer = null;
    if (imgType === "multiRes") {
      const tempUrl = selectedImage + "/config.json";
      console.log("tempUrl", tempUrl);
      fetch(tempUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          // Work with the JSON data from config.json
          ConfigObj = res;
          ConfigObj.multiRes["basePath"] = selectedImage;
          ConfigObj["autoLoad"] = true;
          ConfigObj["compass"] = true;
          ConfigObj["northOffset"] = 247.5;
          ConfigObj["showFullscreenCtrl"] = false;

          // props.dispatch({
          //   type: props.LOADING_MESSAGE,
          //   isLoading: false,
          // });
          props.setImgLoader(false);
          console.log("fetchConfig", ConfigObj);
          viewer = pannellum.viewer(viewerRef.current, ConfigObj);
          setViewerState(viewer);
        })
        .catch((error) => {
          // Handle errors here
          console.error("There was a problem fetching the data:", error);
          // props.dispatch({
          //   type: props.LOADING_MESSAGE,
          //   isLoading: false,
          // });
          props.setImgLoader(false);
          props.setImageViewer360(false);
        });
    } else {
      // props.dispatch({
      //   type: props.LOADING_MESSAGE,
      //   isLoading: false,
      // });
      props.setImgLoader(false);
      ConfigObj = {
        type: "equirectangular",
        autoLoad: true,
        panorama: props.selectedImage.src,
        compass: true,
        northOffset: 247.5,
        showFullscreenCtrl: false,
      };
      viewer = pannellum.viewer(viewerRef.current, ConfigObj);

      setViewerState(viewer);
    }

    return () => {
      if (viewer) {
        viewer.destroy();
      }
    };
  }, [viewerRef]);

  useEffect(() => {
    const tag360 = props.selectedImage.regions
      ? props.selectedImage.regions.threeSixtyTagData
      : [];

    if (viewerState && tag360.length > 0) {
      tag360.forEach((k) => {
        const optional = {
          randomId: k.annotationId,
          title: k.cls,
          description: k.description,
          tags: k.tags,
        };

        viewerFunctions(viewerState, k.pitch, k.yaw, optional);
      });
    }
  }, [viewerState]);

  const screenChangeHandler = () => {
    if (!handle.active) {
      setFullScreen(false);
    }
  };
  const removePaperCard = () => {
    setShowPaper(false);
    // setFormData((prevState) => ({
    //   ...prevState,
    //   title: "",
    //   description: "",
    // }));
    // setTags([]);
  };
  const addHandler = () => {
    // addOffHandler();
    // addOffHandler();
    viewerRef.current.addEventListener("mousedown", handleMouseDown);
    viewerRef.current.addEventListener("mousemove", handleMouseMove);

    viewerRef.current.addEventListener("mouseup", handleMouseUp);
  };
  const addOffHandler = () => {
    if (viewerRef.current) {
      viewerRef.current.removeEventListener("mousedown", handleMouseDown);
      viewerRef.current.removeEventListener("mousemove", handleMouseMove);

      viewerRef.current.removeEventListener("mouseup", handleMouseUp);
    }
  };
  const handleMouseDown = () => {
    isDraggingRef.current = false;
  };

  const handleMouseMove = () => {
    isDraggingRef.current = true;
  };
  const handleMouseUp = (event) => {
    const coords = viewerState.mouseEventToCoords(event);
    const pitch = coords[0];
    const yaw = coords[1];
    //console.log("mousedown", pitch, yaw);
    handleFormSubmit();
    viewerFunctions(viewerState, pitch, yaw, false, event);
  };

  function generateRandomId(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
  // Custom tooltip creation function
  function hotspotTooltip(hotSpotDiv, args) {
    hotSpotDiv.classList.add("custom-tooltip");
    let span = document.createElement("span");
    span.innerHTML = args.title;
    hotSpotDiv.appendChild(span);
    span.style.width = span.scrollWidth - 20 + "px";
    span.style.marginLeft =
      -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + "px";
    span.style.marginTop = -span.scrollHeight - 12 + "px";

    // Add mouseover event listener
    hotSpotDiv.addEventListener("mouseover", function(event) {
      console.log("HoveredOver " + "Over");

      setAddButton(false);
      // addOffHandler();

      // Your custom code for hotspot hover
    });

    // Add mouseout event listener
    hotSpotDiv.addEventListener("mouseout", function(event) {
      console.log("HoveredOver " + "Out");
      setAddButton(true);
      // addHandler();
      // addOffHandler();
      // Your custom code for hotspot mouse out
    });
  }
  const viewerFunctions = (viewer, pitch, yaw, optional, event) => {
    if (!isDraggingRef.current) {
      console.log("viewerFunctions", viewer);
      const randomId = generateRandomId(8);
      removePaperCard();
      if (!optional) {
        setClickPosition({ x: yaw, y: pitch });
        setShowPaper(true);

        setSelectedHotSpot(randomId);
      }
      const hotSpotConfig = {
        pitch: pitch,
        yaw: yaw,
        id: optional?.randomId ? optional?.randomId : randomId,
        type: "info",
        text: optional?.title ? optional?.title : "New Hotspot",
        // URL: "https://example.com",
        clickHandlerFunc: function(event, hotspot) {
          // handleMouseMove();
          // setAddButton(false);
          console.log("HotspotClicked", hotspot);
          setSelectedHotSpot(hotspot.id);
          setFormData((prevState) => ({
            ...prevState,
            title: hotspot.title,
            description: hotspot.description,
          }));
          setTags(hotspot.tags);
          setShowPaper(true);
          setEditButton(true);
        },
        clickHandlerArgs: {
          id: optional?.randomId ? optional?.randomId : randomId,
          title: optional?.title ? optional?.title : "",
          description: optional?.description ? optional?.description : "",
          tags: optional?.tags ? optional?.tags : tags,
          pitch: pitch,
          yaw: yaw,
        },
        createTooltipFunc: hotspotTooltip,
        createTooltipArgs: {
          id: optional?.randomId ? optional?.randomId : randomId,
          title: optional?.title ? optional?.title : "New Hotspot",
          description: optional?.description ? optional?.description : "",
          tags: optional?.tags ? optional?.tags : tags,
        },
      };
      setEditButton(false);
      viewer.addHotSpot(hotSpotConfig);
    }
  };

  useEffect(() => {
    if (viewerState) {
      appendAddButtonHanlder();
    }

    //appendAddPaperHanlder();
    if (addButton && viewerState && startAnnotation) {
      addHandler();
    } else {
      if (viewerState) {
        addOffHandler();
      }
    }
    return () => {
      if (viewerState) {
        addOffHandler();
      }
    };
  }, [addButton, viewerState, startAnnotation, fullScreen]);

  useEffect(() => {
    if (fullScreen) {
      handle.enter();
    } else {
      handle.exit();
    }
  }, [fullScreen]);

  return (
    <DraggableComp
      disabled={
        //true
        props.resizeBtn
      }
    >
      <FullScreen handle={handle} onChange={screenChangeHandler}>
        <div
          style={{
            position: "fixed",
            width:
              props.device === "Mobile"
                ? "80vw"
                : fullScreen
                ? "100vw"
                : "40vw",
          }}
        >
          <div
            style={{
              display: "flex",
              maxHeight: fullScreen ? "100vh" : "50vh",
            }}
          >
            <div style={{ color: "white" }} className={styles.boxViewer}>
              <Tooltip
                title={"Drag"}
                style={{ zIndex: 999999999999999999 }}
                placement="top"
              >
                <div
                  style={{
                    height: "4vh",
                    width: "100%",
                    backgroundColor: "#901da1",
                    // backgroundColor: "red",
                    display: "flex",
                    justifyContent: "flex-space-between",
                    alignItems: "center",
                    borderRadius: "10px 10px 0 0",
                    // flexDirection: "column",
                  }}
                  onClick={(e) => {
                    props.setResizeBtn(true);
                  }}
                  onMouseEnter={() => {
                    props.setResizeBtn(false);
                  }}
                  onMouseLeave={() => {
                    props.setResizeBtn(true);
                  }}
                  id="imgViewerBtnCont"
                >
                  <div className={styles.leftDiv}>
                    <span
                      style={{
                        margin: "4%",
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        fontWeight: 600,
                      }}
                    >
                      {props.selectedImage.name}
                    </span>
                  </div>
                  <div className={styles.rightDiv}>
                    {/* <Tooltip
                      title={"Previous Image"}
                      style={{ zIndex: 999999999999999999 }}
                      placement="top"
                    >
                      <IconButton
                        onClick={() => {
                          props.prevFunc();
                          // props.setImageViewer(false);
                        }}
                        aria-label="delete1"
                        style={{
                          height: "27px",
                          width: "27px",
                          color: "white",
                          margin: "2%",
                        }}
                      >
                        <SkipPreviousIcon />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip
                      title={"Zoom to Layer"}
                      style={{ zIndex: 999999999999999999 }}
                      placement="top"
                    >
                      <IconButton
                        onClick={() => {
                          props.zoomIn();
                          // props.setImageViewer(false);
                        }}
                        aria-label="delete1"
                        style={{
                          height: "27px",
                          width: "27px",
                          color: "white",
                          margin: "2%",
                        }}
                      >
                        <ZoomInIcon />
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip
                      title={"Next Image"}
                      style={{ zIndex: 999999999999999999 }}
                      placement="top"
                    >
                      <IconButton
                        onClick={() => {
                          props.nextFunc();
                          // props.setImageViewer(false);
                        }}
                        aria-label="delete1"
                        style={{
                          height: "27px",
                          width: "27px",
                          color: "white",
                          margin: "2%",
                        }}
                      >
                        <SkipNextIcon />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip
                      title={"Info"}
                      style={{ zIndex: 999999999999999999 }}
                      placement="top"
                    >
                      <IconButton
                        onClick={(e) => {
                          props.sidePanelInfoUpdate(
                            props.selectedImage.getData,
                            props.selectedImage.k
                          );
                          props.setRightDrawer(350);
                          document.getElementsByClassName(
                            "cesium-viewer-toolbar"
                          )[0].style.right = "263px";
                          props.handleInfoDrawerOpen();
                          props.setMediaAccordian(false);
                          setAddButton(false);
                        }}
                        aria-label="delete2"
                        style={{
                          height: "27px",
                          width: "27px",
                          color: "white",
                          margin: "2%",
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip
                      title={"Close Viewer"}
                      style={{ zIndex: 999999999999999999 }}
                      placement="top"
                    >
                      <IconButton
                        onClick={() => {
                          props.closeImageViewerHandler(props.selectedImage.id);
                          console.log("CloseTest", props.selectedImage);
                        }}
                        aria-label="delete1"
                        style={{
                          height: "27px",
                          width: "27px",
                          fontWeight: 800,
                          color: "white",
                          fontSize: 14,
                          margin: "2%",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </Tooltip>
              <div
                className="div-root-89"
                style={{
                  background: "white",
                  textAlign: "right",
                  display: "block",
                }}
              >
                <Select
                  MenuProps={{
                    style: {
                      zIndex: 99999999,
                    },
                  }}
                  className="statusSelect"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  onChange={(e, val) => {
                    props.reviewStatusChange(e.target.value);
                    setStatus(e.target.value);
                  }}
                >
                  <MenuItem
                    style={{
                      display: "block",
                      textAlign: "center",
                    }}
                    value={"view"}
                  >
                    <span
                      className="statusDot"
                      style={{ background: "rgba(0, 0, 0, 0.54)" }}
                    ></span>
                    <h4 className="statusOption">Unreviewed</h4>
                  </MenuItem>
                  <MenuItem
                    style={{
                      display: "block",
                      textAlign: "center",
                    }}
                    value={"reviewed"}
                  >
                    <span
                      className="statusDot"
                      style={{ background: "#00cd00" }}
                    ></span>
                    <h4 className="statusOption">Reviewed</h4>
                  </MenuItem>
                  <MenuItem
                    style={{
                      display: "block",
                      textAlign: "center",
                    }}
                    value={"has issues"}
                  >
                    <span
                      className="statusDot"
                      style={{ background: "#c5c52e" }}
                    ></span>
                    <h4 className="statusOption">Has issues</h4>
                  </MenuItem>
                  <MenuItem
                    style={{
                      display: "block",
                      textAlign: "center",
                    }}
                    value={"critical issue"}
                  >
                    <span
                      className="statusDot"
                      style={{ background: "red" }}
                    ></span>
                    <h4 className="statusOption">Critical issue</h4>
                  </MenuItem>
                </Select>
                <button
                  onClick={() => {
                    props.prevFunc();
                    // props.setImageViewer(false);
                  }}
                  className="MuiButtonBase-root MuiButton-root MuiButton-text WithStyles(ForwardRef(Button))-root-91"
                  tabIndex={0}
                  type="button"
                >
                  <span className="MuiButton-label">
                    <div className="div-root-92">
                      <div className="div-root-93">
                        <svg
                          className="MuiSvgIcon-root"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
                        </svg>
                      </div>
                      <div className="div-root-94">
                        <div className="statusOption">Prev</div>
                      </div>
                    </div>
                  </span>
                  <span className="MuiTouchRipple-root" />
                </button>
                <button
                  onClick={() => {
                    props.nextFunc();
                    // props.setImageViewer(false);
                  }}
                  className="MuiButtonBase-root MuiButton-root MuiButton-text WithStyles(ForwardRef(Button))-root-91"
                  tabIndex={0}
                  type="button"
                >
                  <span className="MuiButton-label">
                    <div className="div-root-92">
                      <div className="div-root-93">
                        <svg
                          className="MuiSvgIcon-root"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                        </svg>
                      </div>
                      <div className="div-root-94">
                        <div className="statusOption">Next</div>
                      </div>
                    </div>
                  </span>
                  <span className="MuiTouchRipple-root" />
                </button>

                <button
                  onClick={(e) => {
                    setFullScreen(!fullScreen);
                    setStartAnnotation(false);
                  }}
                  aria-label="full-screen"
                  onMouseEnter={() => setAddButton(false)}
                  onMouseLeave={() => setAddButton(true)}
                  className="MuiButtonBase-root MuiButton-root MuiButton-text WithStyles(ForwardRef(Button))-root-91"
                  tabIndex={0}
                  type="button"
                >
                  <span className="MuiButton-label">
                    <div className="div-root-92">
                      <div className="div-root-93">
                        <svg
                          className="MuiSvgIcon-root"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
                        </svg>
                      </div>
                      <div className="div-root-94">
                        <div className="statusOption">
                          {fullScreen ? "Window" : "Fullscreen"}
                        </div>
                      </div>
                    </div>
                  </span>
                  <span className="MuiTouchRipple-root" />
                </button>
              </div>

              <div
                ref={viewerRef}
                style={{ width: "100%", height: fullScreen ? "96vh" : "50vh" }}
              />
              {showPaper && (
                <Paper
                  style={{
                    position: "absolute",
                    top: clickPosition.y + 200,
                    left: clickPosition.x + 400,
                    borderRadius: "10px 10px 0 0",
                    width: 300,
                    zIndex: 1000,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      height: "4vh",
                      width: "100%",
                      backgroundColor: "#901da1",
                      display: "flex",
                      justifyContent: "flex-space-between",
                      alignItems: "center",
                      borderRadius: "10px 10px 0 0",
                    }}
                  >
                    <div className={styles.leftDiv}>
                      <span
                        style={{
                          margin: "4%",
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: 600,
                          color: "white",
                          fontSize: 14,
                        }}
                      >
                        {formData.title == "" || formData.title == null
                          ? "New Hotspot"
                          : formData.title}
                      </span>
                    </div>
                    <div className={styles.rightDiv}>
                      <Tooltip
                        title={"Close Viewer"}
                        style={{ zIndex: 999999999999999999 }}
                        placement="top"
                      >
                        <IconButton
                          onClick={() => {
                            removePaperCard();
                          }}
                          aria-label="delete1"
                          style={{
                            height: "27px",
                            width: "27px",
                            fontWeight: 800,
                            color: "white",
                            fontSize: 14,
                            margin: "2%",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  <div style={{ padding: 8 }}>
                    <TextField
                      label="Title"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                      fullWidth
                      style={{
                        fontSize: "0.9rem",
                        marginTop: 8,
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                      }} // Adjusted font size
                      inputProps={{
                        style: {
                          fontSize: "0.9rem",
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: "0.9rem",
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                        },
                      }}
                      disabled={!editPermissions}
                    />
                    <TextField
                      label="Description"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      fullWidth
                      style={{
                        fontSize: "0.9rem",
                        fontFamily:
                          '"Roboto", "Helvetica", "Arial", sans-serif',
                        marginTop: 8,
                      }} // Adjusted font size
                      inputProps={{
                        style: {
                          fontSize: "0.9rem",
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                        },
                      }}
                      disabled={!editPermissions}
                      InputLabelProps={{
                        style: {
                          fontSize: "0.9rem",
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                        },
                      }}
                    />
                    <Autocomplete
                      style={{ marginTop: 8 }}
                      multiple
                      onChange={(a, b, c) => {
                        setTags(b);
                      }}
                      id="tags-standard"
                      options={[]}
                      getOptionLabel={(option) => option}
                      freeSolo
                      fullWidth
                      // value={["Tag1", "Tag2", "Tag3"]}
                      value={tags.length > 0 ? tags : []}
                      renderTags={(value, getLinkProps) => {
                        return (
                          <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {value.map((option, index) => (
                              <Chip
                                title={option.title || option}
                                key={index}
                                variant="outlined"
                                label={option}
                                {...getLinkProps({ index })}
                                style={{
                                  fontSize: "0.9rem",
                                  fontFamily:
                                    '"Roboto", "Helvetica", "Arial", sans-serif',
                                  height: "24px",
                                  maxWidth: "80px",
                                  margin: "2px",
                                }}
                                disabled={!editPermissions}
                              />
                            ))}
                          </div>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tags"
                          placeholder="Tags"
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              fontSize: "0.9rem",
                              fontFamily:
                                '"Roboto", "Helvetica", "Arial", sans-serif',
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              fontSize: "0.9rem",
                              fontFamily:
                                '"Roboto", "Helvetica", "Arial", sans-serif',
                            },
                          }}
                          disabled={!editPermissions}
                        />
                      )}
                    />
                    {editPermissions && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center", // Center the buttons horizontally
                          alignItems: "center", // Align the buttons vertically (if needed)
                          margin: "auto",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleFormSubmit();
                            saveHotSpotsHandler();
                            setStartAnnotation(false);
                          }}
                          style={{
                            display: "inline-block",
                            margin: "15px",
                            fontSize: "0.7rem",
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 600,
                            height: "30px",
                            width: "85px", // Adjusted width to be more appropriate
                            backgroundColor: "#7706ac",
                          }}
                        >
                          {editButton ? "Edit" : "Save"}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            removeHotSpotHandler();
                            removePaperCard();
                            saveHotSpotsHandler();
                            setStartAnnotation(false);
                          }}
                          style={{
                            display: "inline-block",
                            margin: "10px",
                            fontSize: "0.7rem",
                            fontFamily:
                              '"Roboto", "Helvetica", "Arial", sans-serif',
                            fontWeight: 600,
                            height: "30px",
                            width: "85px", // Adjusted width to be more appropriate
                            backgroundColor: "#7706ac",
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    )}
                  </div>
                </Paper>
              )}
            </div>
          </div>
        </div>
      </FullScreen>
    </DraggableComp>
  );
};

export default Image360;
