import React, { useMemo } from "react";
import "./customStyle.css";
import DraggableComp from "../Draggable/Draggable";
import styles from "./ImageAnnotate.module.css";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LaunchIcon from "@material-ui/icons/Launch";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
const HoverDocViewer = (props) => {
  const documents = useMemo(() => props.linkIframe, [props.linkIframe]);

  return (
    <DraggableComp disabled={props.resizeBtn}>
      {/* <DraggableComp disabled={true}> */}
      <div className="iframeWindow">
        <div className="firstDiv">
          <div
            style={{
              color: "white",
              background: "white",
              borderRadius: "10px 10px 0px 0px",
            }}
            className={styles.boxViewer}
          >
            <Tooltip
              title={"Drag"}
              style={{ zIndex: 999999999999999999 }}
              placement="top"
            >
              <div
                style={{
                  height: "4vh",
                  width: "100%",
                  backgroundColor: "#901da1",
                  // backgroundColor: "red",
                  display: "flex",
                  justifyContent: "flex-space-between",
                  alignItems: "center",
                  borderRadius: "10px 10px 0 0",
                  // flexDirection: "column",
                }}
                onClick={(e) => {
                  props.setResizeBtn(!props.resizeBtn);
                }}
                onMouseEnter={() => {
                  props.setResizeBtn(false);
                }}
                onMouseLeave={() => {
                  props.setResizeBtn(true);
                }}
                id="imgViewerBtnCont"
              >
                <div className={styles.leftDiv}>
                  <span
                    style={{
                      margin: "4%",
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: 600,
                    }}
                  ></span>
                </div>
                <div className={styles.rightDiv}>
                  <Tooltip
                    title={"Close Viewer"}
                    style={{ zIndex: 999999999999999999 }}
                    placement="top"
                  >
                    <IconButton
                      onClick={() => {
                        props.onClose();
                      }}
                      aria-label="delete1"
                      style={{
                        height: "27px",
                        width: "27px",
                        color: "white",
                        margin: "2%",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Tooltip>
            <a
              href={documents[0].uri}
              class="pdfDownalod"
              style={{
                position: "absolute",
                top: "89px",
                zIndex: "111",
                left: "15px",
              }}
            >
              <svg width="75%" height="75%" id="Layer_1" viewBox="0 0 24 24">
                <path
                  d="M20.57,9.43A8,8,0,0,0,5.26,10,5,5,0,1,0,5,20h5V18H5a3,3,0,0,1,0-6,3.1,3.1,0,0,1,.79.12l1.12.31.14-1.15a6,6,0,0,1,11.74-.82l.15.54.54.16A3.46,3.46,0,0,1,22,14.5,3.5,3.5,0,0,1,18.5,18H16v2h2.5A5.48,5.48,0,0,0,20.57,9.43Z"
                  fill="#000"
                ></path>
                <polygon
                  points="12 11 12 15.59 10.71 14.29 9.29 15.71 13 19.41 16.71 15.71 15.29 14.29 14 15.59 14 11 12 11"
                  fill="#000"
                ></polygon>
              </svg>
            </a>
            <a
              onClick={() => {
                document.getElementById("react-doc-viewer").requestFullscreen();
              }}
              class="pdfDownalod"
              style={{
                position: "absolute",
                top: "89px",
                zIndex: "111",
                left: "52px",
              }}
            >
              <svg width="70%" height="70%" viewBox="0 0 24 24">
                <path
                  fill="#000"
                  d="M9.29,13.29,4,18.59V17a1,1,0,0,0-2,0v4a1,1,0,0,0,.08.38,1,1,0,0,0,.54.54A1,1,0,0,0,3,22H7a1,1,0,0,0,0-2H5.41l5.3-5.29a1,1,0,0,0-1.42-1.42ZM5.41,4H7A1,1,0,0,0,7,2H3a1,1,0,0,0-.38.08,1,1,0,0,0-.54.54A1,1,0,0,0,2,3V7A1,1,0,0,0,4,7V5.41l5.29,5.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42ZM21,16a1,1,0,0,0-1,1v1.59l-5.29-5.3a1,1,0,0,0-1.42,1.42L18.59,20H17a1,1,0,0,0,0,2h4a1,1,0,0,0,.38-.08,1,1,0,0,0,.54-.54A1,1,0,0,0,22,21V17A1,1,0,0,0,21,16Zm.92-13.38a1,1,0,0,0-.54-.54A1,1,0,0,0,21,2H17a1,1,0,0,0,0,2h1.59l-5.3,5.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L20,5.41V7a1,1,0,0,0,2,0V3A1,1,0,0,0,21.92,2.62Z"
                ></path>
              </svg>
            </a>
            <DocViewer
              style={{
                height: "65vh",
                width: "100vh",
              }}
              key={props.linkIframe}
              pluginRenderers={DocViewerRenderers}
              documents={documents}
            />
          </div>
        </div>
      </div>
    </DraggableComp>
  );
};

export default HoverDocViewer;
